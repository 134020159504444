<script>
import Layout from "../../layouts/main";
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'


export default {
  components: {
    Layout,
    draggable
  },
  data() {
    return {
      directory: null,
      
    };
  },
  created() {
    document.title = this.pagetitle;
  },
  computed :{
    pagetitle () {
      return this.$t("directory.pageTitle");
    },  
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('directories', {
      directories: 'items',
      deleting: 'deleting'
    }),
    items:
    {
      get: function() {
        return this.directories || [];
      },
      set: function(newValue) {
        const list = newValue.map(x => x.id);
        this.reorder({list});
      }
    },
    ...mapGetters('userGroups', {
      everyone: 'everyone',
      userGroups: 'items'
    }),
  },
  methods: {
    ...mapActions('directories', {
      delete: 'delete',
      reorder: 'reorder'
    }),
    getUserGroupName(id) {
      const g = this.userGroups.find(x => x.id === id);
      if(!g) return '???';
      return g.everyone ? this.$t('userGroups.everyOneGroupName') : g.name;
    },
    tryToDelete(id) {
      Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.yesDeleteIt')
      }).then(result => {
        if (result.value) {
          this.delete({id}).then(()=>{
            Swal.fire(this.$t('common.deletedTitle'), this.$t('common.deletedText'), "success");
          }).catch((errors)=>{
            Swal.fire(this.$t('common.cannotDelete'), errors[0], "warning");
          });
        }
      });
    },
    
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" >


   <div class="row align-items-center">
     
     <div class="col">
       <div
          class="d-flex flex-wrap align-items-center justify-content-end "
        >
        <div class="" v-if="currentUser.isAdmin">
          <router-link
            :to="{name:'directory-edit', params: { id: 'new'}}"
            class="btn btn-primary"
           
            > 
            <font-awesome-icon icon="fa-solid fa-plus" />
            <div class="text-sm-end d-none d-md-inline-block ms-2">
              {{ $t('common.newRecord') }}
            </div>
          </router-link>
        </div>
       </div>
     </div>
   </div>
     <div class="row">
        <div class="table-responsive">
          <table
            class="table project-list-table table-nowrap align-middle table-borderless"
            style="margin-bottom:100px"
          >
            <thead>
              <tr>
                <th scope="col" style="width: 60px" v-if="currentUser.isAdmin" >
                  <i class="mdi mdi-drag-variant"></i>
                </th>
                <th scope="col" style="width: 30px" v-if="currentUser.isAdmin"></th>
                <th scope="col" style="width: 30px"></th>
                <th scope="col">{{ $t("directory.fields.name") }}</th>
                <th scope="col">{{ $t("directory.fields.code") }}</th>
                <th scope="col">{{ $t("directory.dirType") }}</th>
                <th scope="col">{{ $t("directory.fields.description") }}</th>
                <th scope="col" style="width: 40px" v-if="currentUser.isAdmin"></th>
              </tr>
            </thead>
            <tbody  v-if="items.length === 0">
              <tr>
                <td colspan="100">
                  <em class="text-muted">
                    {{ $t("directory.noRecordsFound") }}
                  </em>
                </td>
              </tr>
            </tbody>
            <draggable
              tag="tbody"
              v-model="items" 
              group="types" 
              @start="drag=true" 
              @end="drag=false" 
              handle=".drag-area"
              item-key="index"
            >
              <template #item="{element}">
                <tr>
                  <td class="drag-area" v-if="currentUser.isAdmin">
                    <a href="javascript:void(0)" >
                      <i class="mdi mdi-drag font-size-18"></i>
                    </a>
                  </td>
                  <td v-if="currentUser.isAdmin">
                    <router-link :to="{name:'directory-edit', params: { id: element.id }}" class="btn btn-light btn-sm">
                      <font-awesome-icon icon="fa-solid fa-pen" />
                      <div class="text-sm-end d-none d-md-inline-block ms-2">
                        {{ $t("customFields.editItem") }}
                      </div>
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="{name:'directory-items', params: { type: element.id }}" class="btn btn-light btn-sm">
                      <font-awesome-icon icon="fa-solid fa-table-cells" />
                      <div class="text-sm-end d-none d-md-inline-block ms-2">
                        {{ $t("common.records") }}
                      </div>
                    </router-link>
                  </td>
                  <td>
                    <b>{{element.name}}</b>
                  </td>
                   <td>
                    {{element.code}}
                  </td>
                  <td>
                    <span v-if="element.type === 'Data'">{{$t('directory.directoryTypeData')}}</span>
                    <span v-if="element.type === 'Process'">{{$t('directory.directoryTypeProcess')}}</span>
                  </td>
                  <td>
                    {{element.description}}
                  </td>
                  <td v-if="currentUser.isAdmin">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                     
                      @click="tryToDelete(element.id)"
                      class="px-2 text-danger btn btn-light btn-sm"
                      >
                      <font-awesome-icon icon="fa-solid fa-trash-can" />
                      <div class="text-sm-end d-none d-md-inline-block ms-2">
                        {{ $t("directory.deleteItem") }}
                      </div>
                    </a>
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
     </div>
  </Layout>
</template>
